import $ from 'jquery'; 

const isBrowser = typeof window !== "undefined"

if (isBrowser) {
  

$(document).ready(
  function()
   {
    let id = window.location.href
    var url = new URL(id);
    console.log(url.pathname)
    var time = url.pathname.includes('reserva')?18000:3000

        setTimeout(() => {
          console.log('se esta ejecutando el header')
        
  			// cambia el menú de posición dependiendo del tamaño de la pantalla, incluso si se modifica el tamaño de la ventana una vez cargada la pagina
  			var screenWidth = $(window).width();
  			var secondMenu = $('#secondary-menu');
  			var screenHeigtht = $(window).height();
  			console.log(secondMenu)
        var qtyCart = $('#prod-qty');
        //se busca el html que indica el número de items que hay en el carrito y se inserta en el ancla del carrito
        qtyCart.appendTo('#secondary-menu li a.cart');

        let id = window.location.href
            var url = new URL(id);
            console.log(url.pathname)
            if (url.pathname!=='/') {
              //if(height  > screenHeigtht) {
                setTimeout(() => {
                  $('header > .contain-to-grid').addClass('solid-bck');

                }, 2000);
              }

  			if (screenWidth <= 1024) {
          setTimeout(() => {
            console.log('si lo hizo')
  				secondMenu.appendTo('header .title-area').insertAfter('header nav >.title-area > .logo');
          }, 10000);
          
  			}
        if (screenWidth > screenHeigtht) {
          $('.front .field-name-field-img-logo').addClass('desktop-container').removeClass('mobile-container');
            $('.front .field-name-field-img-logo img').attr('src', '/sites/default/files/logo_deskt.svg');
          }else {
            $('.front .field-name-field-img-logo img').attr('src', '/sites/default/files/logoportada.svg');
            $('.front .field-name-field-img-logo').addClass('mobile-container').removeClass('desktop-container');
          }
  			headerBarColor(screenHeigtht);


  			$(window).resize(function(event) {
  				screenHeigtht = $(window).height();
  				var newWidth = $(window).width();
  				if (newWidth <= 1024) {
            console.log($('header .title-area > ul').length)
  					if ($('header .title-area > ul').length < 1 ) {
  						secondMenu.appendTo('header .title-area').insertAfter('header nav >.title-area > .logo');
  						//$('header .top-bar-section #secondary-menu').remove();
              
  					}
            $('.title-area').css('float','right');
            console.log('resize')
  				}else{
  					if ($('header .top-bar-section > ul').length < 2 ) {
  						secondMenu.appendTo('header .top-bar-section');
//$('header .title-area > #secondary-menu').remove();
  					}
            $('.title-area').css('float','');
  				}
          if (newWidth > screenHeigtht) {
            $('.front .field-name-field-img-logo img').attr('src', '/sites/default/files/logo_deskt.svg');
            $('.front .field-name-field-img-logo').addClass('desktop-container').removeClass('mobile-container');
          }else {
            $('.front .field-name-field-img-logo img').attr('src', '/sites/default/files/logoportada.svg');
            $('.front .field-name-field-img-logo').addClass('mobile-container').removeClass('desktop-container');
          }
  				headerBarColor(screenHeigtht);
  			});
  			//Funcion para hacer el cambio de color en el header con base al scroll
  			function headerBarColor(screenHeigtht){
  				$(window).scroll(function() {
				    var height =window.innerHeight;
            let id = window.location.href
            var url = new URL(id);
            console.log(url.pathname)
            if (url.pathname==='/') {
              if(height  > screenHeigtht) {
				        $('header > .contain-to-grid').addClass('solid-bck');
				    }else {
				    	$('header > .contain-to-grid').removeClass('solid-bck');
				    }
            }else{
              $('header > .contain-to-grid').addClass('solid-bck');
            }
				    
				});	
  			}
        $('#secondary-menu > li.has-dropdown').on('click', function(event) {
          if($(window).width() < 1025) {
            $(this).toggleClass('open');
            
          }
          
        });
        $('#main-menu .third-level label').on('click', function(event) {
          var abierto = $(this).hasClass('open');
          if (abierto) {
              $(this).siblings('ul').fadeOut(10);
              $(this).parent('li').siblings('li').children('a, label').animate({right: '0',position: 'relative',transition: 'none'},500);
              $(this).parents('#main-menu').css('margin', '100px 0');
              $('#main-menu').css('cssText', ' ');
              $('#main-menu > li.expanded').removeClass('li-centered');
              $('#main-menu > li').css('cssText', 'height: auto;');
              $(this).parent('.third-level').parent('.dropdown').css('height', 'auto');
              $(this).parent().removeClass('fixed-container');
              $(this).parent('li').parents('.has-dropdown').children('label').animate({right: '0',position: 'relative',transition: 'none'},500);;
              $(this).parent('li').parents('.has-dropdown').siblings('li').children('a, label').animate({right: '0',position: 'relative',transition: 'none'},500);
              $(this).removeClass('fixed-menu open').fadeIn(200);
          }else {
            if ($(window).width() < 1025) {
                $(this).addClass('open');
                $(this).parent('.third-level').parent('.dropdown').css('height', 'calc(100vh - 45px)');
                $('#main-menu > li:not(.expanded)').css('cssText', 'height: 0px;');
                $('#main-menu > li.expanded').addClass('li-centered');
                $(this).parent().addClass('fixed-container');
                $(this).addClass('fixed-menu').fadeOut(200);
                $('#main-menu').css('cssText', 'height: 100vh; margin: 0;');
                $(this).parent('li').siblings('li').children('a, label').animate({right: '100%',position: 'relative',transition: 'none'},500);
                $(this).parent('li').parents('.has-dropdown').children('label').animate({right: '100%',position: 'relative',transition: 'none'},500);;
                $(this).parent('li').parents('.has-dropdown').siblings('li').children('a, label').animate({right: '100%',position: 'relative',transition: 'none'},500);
                $(this).siblings('ul').fadeIn();
                $(this).fadeIn();
            }
          }
        });


        $('.toggle-topbar').on('click', function(event) {
          //revertMenuTransition();
          console.log('clic')
          if ($( ".top-bar" ).hasClass( "expanded" )) {
            $('.top-bar').removeClass('expanded');
          }else{
         
          $('.top-bar').addClass('expanded');
          }
        });
  	

        $('.open').on('click', function(event) {
          //revertMenuTransition();
          console.log('clic2')
          $(this).addClass('notopen');
          $(this).removeClass('open');
          $('.top-bar').removeClass('expanded');
        });
    $('#main-menu .dropdown li a.active').parents('li.not-click').children('label').addClass('active');
        

    
    $( ".kuQRLd" ).mouseover(function() {
      console.log('mouse')
      $(".kuQRLd").css("background-color", "#000000");
    });
  }, time);
});
}