import React,{useState} from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import $ from 'jquery'
import * as app from "../fetch/fetchApi";
import { LazyLoadComponent } from "react-lazy-load-image-component";

registerLocale('es', es)
const NewsLetter = () => {

  const [startDate, setStartDate] =useState(null);
  const [alerta, setAlerta] =useState('');
  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {
  $('#suscripcion-a-lista-de-correo-entityform-edit-form').unbind('submit').on("submit",function(){
    console.log('clicleo')
    console.log($('#edit-field-nombre-und-0-value--2'))
    console.log($('#fecha'))
    console.log($('#edit-field-email-und-0-email'))



 var formDataReserva = {"nombre":$('#edit-field-nombre-und-0-value--2').val(),"fecha": Date.parse($('#fecha').val()),"email":$('#edit-field-email-und-0-email').val()}

       console.log(JSON.stringify(formDataReserva))
     
       document.getElementById('fecha').innerText=''
       document.getElementById('edit-field-email-und-0-email').innerText=''
       document.getElementById('edit-field-nombre-und-0-value--2').innerText=''
$('#edit-field-nombre-und-0-value--2').val('')
       $('#fecha').val('')
       $('#edit-field-email-und-0-email').val('')
fetch(app.url+'/node-api/m22_formulario_newsletter/', {
       method: 'POST', // or 'PUT'
       headers:{'Content-type':'application/json'},
       body: JSON.stringify(formDataReserva), // data can be `string` or {object}!
      
     }).then(res => res.json())
     .catch(error => {
      //errores='error'
      
        console.error('Error:', error)
        setAlerta('errorNews')
         setTimeout(() => {
           setAlerta('')
        }, 3000);
    })
     .then(response => {
         console.log(response)
         var respuesta= response[0]
        if (response.status==="sucess") {
           
        // sucess='true';
        console.log('Success:', response.message) 
        setAlerta('successNews')
       
        setTimeout(() => {
          setAlerta('')
       }, 3000);
      }else if(response.status=="error"){
         //errores='error'
         console.error('Error:', response)
        setAlerta('errorNews')
         setTimeout(() => {
           setAlerta('')
        }, 3000);
      }
    });
 
    //$('#suscripcion-a-lista-de-correo-entityform-edit-form').reset();

 })
}

  return (
    <section class="block block-views block-views-entityform-block block-views-entityform-block">
        <div class="view view-entityform view-id-entityform view-display-id-block view-dom-id-cf8f9a29eae84006911158171b1d72ea">
            <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first views-row-last">
                    <div class="entity entity-entityform-type entityform-type-entityform-type clearfix">
                        <h3 className="tituloInteres text-center"> Newsletter</h3>
                        <div class="content"> 
                        <div id="clientsidevalidation-suscripcion-a-lista-de-correo-entityform-edit-form-errors" class="messages error clientside-error" style={{display: 'none'}}>
                            <ul></ul>
                        </div>
                        <form class="entityform entitytype-suscripcion_a_lista_de_correo-form antibot gtmSubmitNL-processed" action="javascript:void(0);" method="post" id="suscripcion-a-lista-de-correo-entityform-edit-form" accept-charset="UTF-8" novalidate="novalidate">
                            
                                <div class="pre-instructions ">
                                    <p class="paragraph-sb text-center">Recibe información y promociones especiales.</p>
                                </div>
                                <input type="hidden" name="form_build_id" value="form-JFTf3W5rl8ADMONdw4-wUPZlz16CyDDdcA-Vq8e0lS8"/>
                                <input type="hidden" name="form_id" value="suscripcion_a_lista_de_correo_entityform_edit_form"/>
  <input type="hidden" name="antibot_key" value="266850925a1c09d4ca0cba6654a4bac7"/>
                                <div class="required-fields group-nombre-mail field-group-div d-flex row justify-content-center" style={{width:'100%'}}>
                                    <div class="field-type-text field-name-field-nombre field-widget-text-textfield col-lg-4 col-md-6 col-12" id="edit-field-nombre--2" >
                                        <div id="field-nombre-add-more-wrapper--2">
                                            <div class="form-item form-type-textfield form-item-field-nombre-und-0-value">
                                                <LazyLoadComponent>
                                                <input class="text-full form-text required" placeholder="Nombre" type="text" id="edit-field-nombre-und-0-value--2" name="field_nombre[und][0][value]" size="60" maxlength="50"/>
                                                </LazyLoadComponent>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-type-email field-name-field-email field-widget-email-textfield  col-lg-4 col-md-6 col-12" id="edit-field-email">
                                        <div id="field-email-add-more-wrapper">
                                            <div class="text-full-wrapper">
                                                <div class="form-item form-type-textfield form-item-field-email-und-0-email"> 
                                                {/*<label for="edit-field-email-und-0-email">Correo electrónico 
                                                <span class="form-required" title="Este campo es obligatorio.">*</span>
  </label> */}                                                          
                                                <LazyLoadComponent>
                                                <input type="text" placeholder="Correo electrónico" id="edit-field-email-und-0-email" name="field_email[und][0][email]" size="60" maxlength="128" class="form-text required"/>
                                                </LazyLoadComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={' field-type-text field-name-field-cumple field-widget-text-textfield  col-lg-4 col-md-6 col-12'}>
                                        <div className="form-item form-type-textfield form-item-field-email-und-0-email" id="#cumple">
                                       {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  locale="es" id="fecha"   placeholderText="Fecha de nacimiento" className="calendario2"  />*/}
                                        <DatePicker
      selected={startDate}
      id="fecha"   placeholderText="Fecha de nacimiento" className="calendario2 cumple" 
      onChange={(date) => setStartDate(date)}
      peekNextMonth
      showMonthDropdown
      maxDate={Date.now()}
      showYearDropdown
      dropdownMode="select"
      locale="es"
    />
                                        </div>
                                    </div>
                                    {/*<div class="field-type-email field-name-field-email field-widget-email-textfield form-wrapper" id="edit-field-email">
                                        <div id="field-email-add-more-wrapper">
                                            <div class="text-full-wrapper">
                                                <div class="form-item form-type-textfield form-item-field-email-und-0-email"> 
                                                <label for="edit-field-email-und-0-email">Fecha de Nacimiento 
                                                <span class="form-required" title="Este campo es obligatorio.">*</span>
                                                </label> 
                                                    
                                                </div>
                                            </div>
                                        </div>
  </div>*/}
                                </div>
                                <button class="secondary news button radius form-submit" id="sendMail" name="op" value="Suscribirme" type="submit">Suscribirme</button>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    { alerta=='successNews'? <div id="add-to-cart-toast-message" style={{visibility:'visible'}} class="paragraph-sb">Agregado a la subscripción</div>:''}
  {alerta=='errorNews'?<div id="add-to-cart-toast-message" style={{visibility:'visible'}} class=""><span class="paragraph-sb"><strong>Error:</strong> envie de nuevo el correo</span></div>:''}
  
</section>
  );
};

export default NewsLetter;