import React,{useEffect,useState} from "react";
import {Link} from "gatsby";
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
import BannerMensaje from "./banner_mensaje";
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import $ from 'jquery';
const axios = require('axios');


const jar = app.jar;
const client = wrapper(axios.create({ jar }));
 
//let image = "https://estacas.dev.m22.mx/sites/all/themes/tickets/img/Logo.svg";

   const isBrowser = typeof window !== "undefined"

const menus=(data)=>{
   console.log(Object.values(data))
   data=Object.values(data)
   var component=[];
   var component2=[];
   var component3=[];


   for (let index = 0; index < data.length; index++) {
      
      if (data[index].hijos) {
         
        for (let index2 = 0; index2 < data[index].hijos.length; index2++) {
           console.log(data[index].hijos.length)
         
           //console.log(data[index].hijos[index2].link.link_title)
           if (data[index].hijos[index2].hijos) {
            console.log(data[index].hijos[index2].hijos.length)
              for (let index3 = 0; index3 < data[index].hijos[index2].hijos.length; index3++) {
               var url = data[index].hijos[index2].hijos[index3].link.link_path; 
               var id =data[index].hijos[index2].hijos[index3].link.href
               //url=url.replace('atracciones/','')
               console.log(url)
               /* if (data[index].hijos[index2].hijos[index3].link.link_title ==="Hotel Las Estacas"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Camping"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Glamping"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Suite"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="La Estancia, hotel para perros"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Restaurante Los Amates"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Quinta Margarita") {*/ //"node?id="+data[index].hijos[index2].hijos[index3].link.options.query.id
                  //component3.push(<li  class="text-start justify-content-start leaf"> <a name={id.includes('node/')===true? id.replace('node/',''):''} href={url.includes('hospedaje-individual')===true?url.replace('hospedaje-individual','hospedaje'):url}>{data[index].hijos[index2].hijos[index3].link.link_title}</a></li>) 
                  component3.push(<li  class="text-start justify-content-start leaf">
                    
                      <a href={url.includes('https')===true? url:'/'+url}>{data[index].hijos[index2].hijos[index3].link.link_title}</a>
                     
                      </li>) 

                  console.log(url)
                 //}else{
               //component3.push(<li class="text-start justify-content-start leaf"> <a href={data[index].hijos[index2].hijos[index3].link.link_path}>{data[index].hijos[index2].hijos[index3].link.link_title}</a></li>) 
                 //console.log(data[index].hijos[index2].hijos[index3].link.link_title)
              //}
            }
            component2.push(<li name="prueba" class=" has-dropdown  not-click text-start justify-content-start third-level"> <LazyLoadComponent> <label>{data[index].hijos[index2].link.link_title}</label><LazyLoadImage src="https://lasestacas.com/sites/all/themes/tickets/2019/svg/menu-active.svg" /><ul class="dropdown">{component3}</ul></LazyLoadComponent></li>) 

           }else{
            var url3=data[index].hijos[index2].link.link_path
            component2.push(<li name="prueba" class="text-start justify-content-start leaf"> <a href={url3.includes('https')===true?data[index].hijos[index2].link.link_path:'/'+data[index].hijos[index2].link.link_path}>{data[index].hijos[index2].link.link_title}</a></li>) 

           }
           component3=[];
        }
        component.push(<li name="prueba" class="has-dropdown not-click me-3" > <LazyLoadComponent> <label>{data[index].titulo}</label><ul class="dropdown">{component2}</ul></LazyLoadComponent></li>);
      }else{
         var url2= data[index].path
         component.push(<li name="prueba" class="leaf me-3"> <a href={url2.includes('https')===true?data[index].path:'/'+data[index].path}>{data[index].titulo}</a></li>);
      }
     component2=[];
   }
   console.log(component)
   return component;
}



const BannerMobile = ({data}) => {
   //const isBrowser = typeof window !== "undefined"

var cantidad=0;
const [datos, setData] = useState(0)

    if (isBrowser) {


/*$(document).ready(function () {
   peticion();
})*/
}

useEffect(() => {
   // get data from GitHub api
   
      // set data for the number of stars
 

   

   peticion()
}, [])


async function peticion() {
   
 
      
   await client.get(app.url+'/node-api/m22_get_cantidad/1', {
      // method: 'POST', // or 'PUT'
      // credentials: 'include',
       withCredentials: true,
       //jar:CookieJar,
       //data: datos, // data can be `string` or {object}!
       headers: {
         // Overwrite Axios's automatically set Content-Type
         crossDomain:true,
         'Content-Type': 'application/json'
       },
     })
     .then(function (response) {
      var num = response.data===null?0:response.data
        setData(num)
        //cantidad=response.data[0]
       console.log("cantidad ",response.data);
       
     })
     .catch(function (error) {
      console.log(error)
     })
      
   }
/**
 fetch('https://silvio.estacas.dev.m22.mx/node-api/m22_insert_formulario_contacto/', {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(formData, null, 4), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
    .catch(error => {
       console.error('Error:', error)
       setAlerta('error')
       setTimeout(() => {
         setAlerta('')
      }, 3000);
   })
    .then(response => {
       console.log('Success:', response) 
      setAlerta('success')
      setTimeout(() => {
         setAlerta('')
      }, 3000);
   });
 */
  // const isBrowser = typeof window !== "undefined"
if (isBrowser) {
   

   window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      console.log('change localstorage')
       setData(localStorage.getItem('cantidadProds'))   
    });
   }
  return (
    
    <header id="menuNav" role="banner" class="l-header" name='normal'>
            <BannerMensaje />

      <div class={'contain-to-grid'}>
       <nav class="top-bar" data-topbar="" data-options="">
          <ul class="title-area" style={{width:'unset'}}>
             <li class="name">
                <h1><Link to="/" rel="home" title="Las Estacas Inicio">Las Estacas</Link></h1>
             </li>
             <li class="logo"><Link to="/" rel="home" title="Las Estacas Inicio" > <LazyLoadComponent> <LazyLoadImage class="logo" src={app.url+"/sites/all/themes/tickets/img/Logo.svg"} alt="Las Estacas logo" title="Las Estacas Inicio"/> </LazyLoadComponent></Link></li>
          </ul >
          <section class="top-bar-section">
          <ul id="main-menu" class="main-nav left">
          {menus(data)}
      </ul>
             <ul id="secondary-menu" class="secondary link-list right oficial">
                {/*<li class="first expanded has-dropdown not-click">
                   <label>user-tab</label>
                   <ul class="dropdown">
                      <li class="first leaf login-modal" id="modal-user"><a href={app.url+"/cart#inicio"} id="modal-user" class="login-modal">Iniciar sesión</a></li>
                      <li class="last leaf" id="modal-register"><a href={app.url+"/cart#registro"} id="modal-register">Registrarse</a></li>
                   </ul>
  </li>*/}
                <li class="last leaf cart" name="cart"><Link to={app.url+"/cart"} name="cart" class="cart">cart{datos>0?<p id="prod-qty" class="circle number">{datos}</p>:''}</Link></li>
                {console.log(datos)}
                <li class="toggle-topbar menu-icon notopen d-lg-none d-block"> <a href="#"> <span class="button-theme">MENÚ</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </a></li>
             </ul>
          </section>
       </nav>
    </div>
 </header>
  );
};

export default BannerMobile;
