import React from "react";


const BannerMensaje = () => {
  return (
<div class="notice" style={{position: 'relative', zIndex: '9999',background: 'orange',textAlign: 'center',fontWeight: '500',color: 'white',fontSize:' .85em',padding: '3px 0'}}>
    <a href="https://lasestacas.com/medidas-ante-covid-19" style={{color:'white', textDecoration: 'underline', fontWeight: 'bold'}}>Consulta aquí nuestras medidas Covid.</a>
</div>
  );
};

export default BannerMensaje;