import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const FooterSocial = () => {
  return (
    <div class="row d-block d-sm-none" id="widget-contacto-footer">
        <div class="small-3 columns"> 
        <button onclick="window.open('https://waze.com/ul?ll=18.732481,-99.114636&amp;navigate=yes','_blank');" type="button" name="waze" > 
        <LazyLoadImage width="25" height="25" src="https://lasestacas.com/sites/all/themes/tickets/2019/svg/waze.svg" alt="waze"/> 
        </button>
        </div>
        <div class="small-3 columns"> 
        <button onclick="window.open('https://www.google.com/maps/dir/?op=Enviar&amp;api=1&amp;destination=Las+Estacas,+Morelos','_blank');" type="button" name="maps"> 
        <LazyLoadImage width="25" height="25" src="https://lasestacas.com/sites/all/themes/tickets/2019/svg/maps.svg" alt="google maps"/> 
        </button>
        </div>
        <div class="small-3 columns">
            <button onclick="window.open(&quot;tel:734 - 120 - 2916&quot;, &quot;_blank&quot;);" type="button" name="celular">
                <LazyLoadImage width="25" height="25" src="https://lasestacas.com/sites/all/themes/tickets/2019/svg/phone-focused.svg" alt="teléfono"/>
                </button>
                </div>
                <div class="small-3 columns">
                    <button onclick="window.open(&quot;https://api.whatsapp.com/send?phone=527341142287&quot;,&quot;_blank&quot;);" type="button" name="whatsapp">
                        <LazyLoadImage width="25" height="25" src="https://lasestacas.com/sites/all/themes/tickets/2019/svg/whatsapp.svg" alt="whatsapp"/>
                        </button>
                        </div>
                        </div>
  );
};

export default FooterSocial;