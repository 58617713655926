import React from "react";
import * as app from "../fetch/fetchApi";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Politicas = () => {
  return (
<section class="block block-menu block-menu-menu-footer-menu block-menu-menu-footer-menu">
    <ul class="menu">
        <li class="first leaf">
            <a href={app.url+"/terminos-y-condiciones-de-uso"}>Términos de uso</a>
        </li>
        <li class="leaf mb-0" style={{paddingBottom:'17px',paddingTop:'17px'}}>
            <LazyLoadImage width="154" height="32" src='https://lasestacas.com/sites/all/themes/tickets/img/Logo_Blanco.svg'/>
        </li>
        <li class="last leaf">
            <a href={app.url+"/aviso-de-privacidad"}>Políticas de privacidad</a>
        </li>
    </ul> 
</section>
  );
};

export default Politicas;