import React,{useEffect,useState} from "react";
import {Link} from "gatsby";
import $ from "jquery"
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

const axios = require('axios');


const jar = app.jar;
const client = wrapper(axios.create({ jar }));
//let image = "https://estacas.dev.m22.mx/sites/all/themes/tickets/img/Logo.svg";
const isBrowser = typeof window !== "undefined"


const menus=(data)=>{
   console.log(Object.values(data))
   data=Object.values(data)
   var component=[];
   var component2=[];
   var component3=[];

 


   for (let index = 0; index < data.length; index++) {
      
      if (data[index].hijos) {
         
        for (let index2 = 0; index2 < data[index].hijos.length; index2++) {
           console.log(data[index].hijos.length)
         
           //console.log(data[index].hijos[index2].link.link_title)
           if (data[index].hijos[index2].hijos) {
            console.log(data[index].hijos[index2].hijos.length)
              for (let index3 = 0; index3 < data[index].hijos[index2].hijos.length; index3++) {
               var url = data[index].hijos[index2].hijos[index3].link.link_path; 
               var id =data[index].hijos[index2].hijos[index3].link.href
               //url=url.replace('atracciones/','')
               console.log(url)
               /* if (data[index].hijos[index2].hijos[index3].link.link_title ==="Hotel Las Estacas"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Camping"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Glamping"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Suite"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="La Estancia, hotel para perros"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Restaurante Los Amates"||
                 data[index].hijos[index2].hijos[index3].link.link_title ==="Quinta Margarita") {*/ //"node?id="+data[index].hijos[index2].hijos[index3].link.options.query.id
                  //component3.push(<li  class="text-start justify-content-start leaf"> <a name={id.includes('node/')===true? id.replace('node/',''):''} href={url.includes('hospedaje-individual')===true?url.replace('hospedaje-individual','hospedaje'):url}>{data[index].hijos[index2].hijos[index3].link.link_title}</a></li>) 
                  component3.push(<li    class="text-start justify-content-start sty leaf d-block" >
                    
                      <a href={url.includes('https')===true? url:'/'+url}>{data[index].hijos[index2].hijos[index3].link.link_title}</a>
                     
                      </li>) 

                  console.log(url)
                 //}else{
               //component3.push(<li class="text-start justify-content-start leaf"> <a href={data[index].hijos[index2].hijos[index3].link.link_path}>{data[index].hijos[index2].hijos[index3].link.link_title}</a></li>) 
                 //console.log(data[index].hijos[index2].hijos[index3].link.link_title) //<img src="https://lasestacas.com/sites/all/themes/tickets/2019/svg/menu-active.svg" />
              //}
            }
            component2.push(<li name="prueba" onClick={quitBottom(index)}  class=" has-dropdown   not-click text-start justify-content-start third-level d-block"> <LazyLoadComponent> <label>{data[index].hijos[index2].link.link_title}</label><ul style={{height:'100%'}} class={"dropdown hijos3  sty"}>{component3}</ul></LazyLoadComponent></li>) 

           }else{
            var url3=data[index].hijos[index2].link.link_path
            component2.push(<li name="prueba"   class="text-start justify-content-start leaf"> <a href={url3.includes('https')===true?data[index].hijos[index2].link.link_path:'/'+data[index].hijos[index2].link.link_path}>{data[index].hijos[index2].link.link_title}</a></li>) 

           }
           component3=[];
        }
        component.push(<li name="prueba" onClick={addStyles(index)}   class={"has-dropdown  not-click me-3 clic"+index} > <LazyLoadComponent> <label >{data[index].titulo}</label><ul class={"dropdown heig"+index+"  prueba"+index}>{component2}</ul> </LazyLoadComponent></li>);
      }else{
         var url2= data[index].path
         component.push(<li name="prueba"  class="leaf  me-3"> <a href={url2.includes('https')===true?data[index].path:'/'+data[index].path}>{data[index].titulo}</a></li>);
      }
     component2=[];
   }
   console.log(component)
   return component;
}
var clic = false
var count=0;
var clic2 = false
var count2=0;
function addStyles(index) {
   $('.clic'+index).unbind('click').click(function() {
      console.log('clicleo')
      //$('.heig'+index).css("height","auto")

      if (clic2===true) {
         
         //clic=false
         $('.heig'+index).css("height","0")

      }
      console.log(clic)
      if (clic2===false) {
         
         $('.heig'+index).css("height","auto")

      }
      count2=count2+1;
      if (count2%2===0) {
         clic2=false
      }else{
         clic2=true
      }
   })
   setTimeout(() => {
      
   
   $('.prueba'+index).unbind('click').click(function() {
      console.log(clic)
      //$('.hijos3').css("padding-bottom","500px")
      if (clic===true) {
         $('.sty').css("height","0")

         $('.prueba'+index).css("padding-bottom","unset")
         //clic=false
      }
      console.log(clic)
      if (clic===false) {
         $('.sty').css("height","auto")
         $('.prueba'+index).css("padding-bottom","500px")
         //clic=true;
      }
      count=count+1;
      if (count%2===0) {
         clic=false
      }else{
         clic=true
      }
      console.log(clic)

      

   });
}, 1000);
   
}
function quitBottom(index) {
  
      console.log('clic2')
      //$('.hijos3').css("padding-bottom","500px")
     // $('.prueba'+index).css("padding-bottom","0")
  
}
setTimeout(() => {
   if (isBrowser) {
   $('.sty').attr("style", " ");
   $('.not-click').click(function() {
      //console.log('clic')
      //$('.hijos3').css("padding-bottom","500px")
     // $('.prueba0').css("padding-bottom","500px")
   });

   $('.fixed-container').click(function() {
      console.log('clic2')
      //$('.hijos3').css("padding-bottom","500px")
      $('.prueba0').css("padding-bottom","0px")
   })
}
}, 2000);


/*setTimeout(() => {
   

   const elements = document.getElementsByClassName('secondary');
   console.log(elements)
   
   elements[1].remove();
   }, 2000);*/
const Banner = ({data}) => {
   var cantidad=0;

   const [datos, setData] = useState(0)


   async function peticion() {
      
    
      await client.get(app.url+'/node-api/m22_get_cantidad/1', {
       // method: 'POST', // or 'PUT'
       // credentials: 'include',
        withCredentials: true,
        //jar:CookieJar,
        //data: datos, // data can be `string` or {object}!
        headers: {
          // Overwrite Axios's automatically set Content-Type
          crossDomain:true,
          'Content-Type': 'application/json'
        },
      })
      .then(function (response) {
         setData(response.data[0])
         cantidad=response.data[0]
        console.log(response.data[0]);
        
      })
      .catch(function (error) {
       console.log(error)
      });
   }


   useEffect(() => {
      // get data from GitHub api
      
         // set data for the number of stars
    
    
      peticion()
   }, [])
  return (
    
    <header id="menuNav" role="banner" class="l-header" name="mobile">
      {/*<BannerMensaje />*/}
      <div class={'contain-to-grid'}>
       <nav class="top-bar" data-topbar="" data-options="">
          <ul class="title-area">
             <li class="name">
                <h1><a href="/" rel="home" title="Las Estacas Inicio">Las Estacas</a></h1>
             </li>
             <li class="logo"><a href="/" rel="home" title="Las Estacas Inicio" > <LazyLoadComponent> <LazyLoadImage width="153" height="32" class="logo" src="https://lasestacas.com/sites/all/themes/tickets/img/Logo.svg" alt="Las Estacas logo" title="Las Estacas Inicio"/></LazyLoadComponent></a></li>
          </ul >
          <section class="top-bar-section">
          <ul id="main-menu" class="main-nav left tab menuWid" style={{width:'100%'}}>
          {menus(data)}
      </ul>
             <ul id="secondary-menu" class="secondary link-list right">
               {/*} <li class="first expanded has-dropdown not-click">
                   <label>user-tab</label>
                   <ul class="dropdown">
                      <li class="first leaf login-modal" id="modal-user"><a href={app.url+"/cart#inicio"} id="modal-user" class="login-modal">Iniciar sesión</a></li>
                      <li class="last leaf" id="modal-register"><a href={app.url+"/cart#registro"} id="modal-register">Registrarse</a></li>
                   </ul>
                </li>*/}
                <li class="last leaf cart" name="cart"><Link to={app.url+"/cart"} name="cart" class="cart">cart{datos>0?<p id="prod-qty" class="circle number">{datos}</p>:''}</Link></li>
                <li class="toggle-topbar menu-icon notopen d-xl-none d-block"> <a href="#"> <span class="button-theme">MENÚ</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </a></li>
             </ul>
          </section>
       </nav>
    </div>
 </header>
  );
};

export default Banner;
